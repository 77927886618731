import React from "react"

import Page from "../components/layout/Page"
import SEO from "../components/seo"

import PageHeader from "../components/layout/PageHeader"

const IntellectualPropertyPage = () => (
  <>
    <SEO page="privacy-policy" title="SignMonkey Intellectual Property" />
    <Page
        breadcrumb={[
            {title: "Home", url:"/"},
            {title: "Intellectual Property", url:null}
        ]}
    >
      <PageHeader title="SignMonkey Intellectual Property" />

      <p>
          <a href="https://patents.google.com/patent/US8840266B1/en?oq=us+8840266+b1" target="_blank">
              Modular Power-Delivery System - EZLit Plug-n-Play
          </a>
      </p>
      <p>
          <a href="https://tsdr.uspto.gov/#caseNumber=86606518&caseSearchType=US_APPLICATION&caseType=DEFAULT&searchType=statusSearch" target="_blank">
              SignMonkey.com Trademark
          </a>
      </p>
      <p>
          <a href="https://tsdr.uspto.gov/#caseNumber=88668038&caseSearchType=US_APPLICATION&caseType=DEFAULT&searchType=statusSearch" target="_blank">
              SignMonkey Trademark
          </a>
			</p>
    </Page>
  </>
)

export default IntellectualPropertyPage